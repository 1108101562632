'use client';

import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { AppProvider } from 'src/context/app';
import {
    SharedProvider,
    getAnonymousUserId,
    getUserUuid,
    ToastProvider,
} from '@get-mosh/msh-shared';
import Brands from './constants/Brands';
import DatadogInit from 'src/analytics/Datadog';
import { datadogRum } from '@datadog/browser-rum';

type ProvidersProps = {
    children: React.ReactNode;
};

function Providers({ children }: ProvidersProps) {
    const [client] = useState(new QueryClient());

    useEffect(() => {
        const userId = getUserUuid();
        const anonymousId = getAnonymousUserId();

        datadogRum.setUser({
            id: userId,
            anonymous_id: anonymousId,
            brand: Brands.MOSH,
        });
    }, []);

    return (
        <AppProvider>
            <QueryClientProvider client={client}>
                <SharedProvider theme="mosh">
                    <ToastProvider>
                        <DatadogInit />
                        {children}
                    </ToastProvider>
                </SharedProvider>
            </QueryClientProvider>
        </AppProvider>
    );
}

export default Providers;
